.root {
	composes: g-grid-container from global;

	@media (--small) {
		/* 
			For mobile, we need to override g-grid-container to make this element
			full bleed.
		*/
		position: relative;
		width: 100%;
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
		margin-left: 0;
		margin-right: 0;
	}
}

.background {
	background: var(--token-color-surface-strong);

	@media (--medium-up) {
		border-radius: var(--hdsplus-spacing-03);
		overflow: hidden;
		border: 1px solid var(--token-color-border-faint);
	}
}

.content {
	composes: g-grid-container from global;
	composes: g-grid from global;
	row-gap: var(--hdsplus-spacing-08);
	align-items: center;

	/* Override on g-grid-container to allow full-bleed images */
	@media (--medium-up) {
		padding-left: 0;
		padding-right: 0;
	}
}

.text {
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	row-gap: var(--hdsplus-spacing-05);
	padding-top: var(--hdsplus-spacing-11);

	@media (--medium-up) {
		padding-left: var(--hdsplus-spacing-13);
		padding-right: var(--hdsplus-spacing-11);
		padding-bottom: var(--hdsplus-spacing-11);
		grid-column: 1 / 7;
		display: flex;

		@nest .reverse & {
			grid-column: 7 / -1;
			padding-left: var(--hdsplus-spacing-11);
			padding-right: var(--hdsplus-spacing-13);
		}
	}
}

.images {
	grid-column: 1 / -1;
	height: 100%;
	padding-bottom: var(--hdsplus-spacing-07);

	@media (--medium-up) {
		padding-bottom: 0;
		grid-column: 7 / -1;

		@nest .reverse & {
			grid-row: 1;
			grid-column: 1 / 7;
		}
	}
}

.desktopImage,
.mobileImage,
.mobileAndDesktopImage {
	height: 100%;

	& img {
		max-width: 100%;
		height: auto;
	}
}

.mobileImage,
.mobileAndDesktopImage {
	display: flex;

	& img {
		@media (--small) {
			border-radius: 8px;
		}
	}

	@media (--small) {
		justify-content: center;
	}
}

.mobileImage {
	justify-content: center;

	@media (--large) {
		display: none;
	}
}

.desktopImage {
	display: none;

	@media (--large) {
		display: flex;
	}
}

.cover {
	object-fit: cover;

	@nest :global(.hashidaysConference) & {
		object-position: top left;
	}
}

.contain {
	object-fit: contain;
}

.mobile {
	display: flex;

	@media (--medium-up) {
		display: none;
	}
}

.tablet {
	display: none;

	@media (--medium-up) {
		display: flex;
	}

	@media (--large) {
		display: none;
	}
}

.desktop {
	display: none;

	@media (--large) {
		display: flex;
	}
}
