.root {
	position: relative;
	composes: g-grid-container from global;
	background-color: var(--token-color-surface-primary);
	
	&.faint {
		background-color: var(--token-color-surface-faint);
	}

	@nest :global(.hashiconfConference) &::after {
		content: '';
		position: absolute;
		bottom: 0%;
		left: 50%;
		transform: translate3d(-50%, 0%, 0);
		width: 80%;
		height: 80%;
		background: linear-gradient(102deg, rgba(237, 59, 124, 0.70) 0.24%, rgba(160, 103, 218, 0.70) 26.54%, rgba(58, 128, 250, 0.70) 74.89%, rgba(0, 129, 150, 0.70) 100.24%);
		filter: blur(80px);
		z-index: 0;
	}
}

.intro {
	composes: g-grid from global;
	text-align: center;
	margin-bottom: var(--hdsplus-spacing-07);
	
	@media (--medium-up) {
		text-align: left;
		margin-bottom: var(--hdsplus-spacing-10)
	}
}

.eyebrow {
	grid-column: 1 / -1;
	color: var(--token-color-foreground-faint);
	composes: hdsplus-typography-label from global;
	text-transform: uppercase;
	font-weight: 600;
	margin-bottom: var(--hdsplus-spacing-03);

	@media (--medium-up) {
		margin-bottom: var(--hdsplus-spacing-04);
	}
}

.headline {
	grid-column: 1 / -1;
	margin-bottom: var(--hdsplus-spacing-05);
	color: var(--token-color-foreground-strong);
	font-family: var(--hdsplus-typography-display-expressive-300-font-family); 
	font-size: var(--hdsplus-typography-display-expressive-300-font-size); 
	line-height: var(--hdsplus-typography-display-expressive-300-line-height); 
	letter-spacing: var(--hdsplus-typography-display-expressive-300-letter-spacing); 
	font-weight: 700;

	@media (--medium-up) {
		grid-column: 1 / 8;
		font-family: var(--hdsplus-typography-display-expressive-400-font-family); 
		font-size: var(--hdsplus-typography-display-expressive-400-font-size); 
		line-height: var(--hdsplus-typography-display-expressive-400-line-height); 
		letter-spacing: var(--hdsplus-typography-display-expressive-400-letter-spacing); 
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-600-font-family); 
		font-size: var(--hdsplus-typography-display-expressive-600-font-size); 
		line-height: var(--hdsplus-typography-display-expressive-600-line-height); 
		letter-spacing: var(--hdsplus-typography-display-expressive-600-letter-spacing); 
	}
}

.description {
	grid-column: 1 / -1;
	color: var(--token-color-foreground-primary);
	font-size: var(--hdsplus-typography-body-200-font-size);
	line-height: var(--hdsplus-typography-body-200-line-height);
	font-weight: 400;

	@media (--medium-up) {
		grid-column: 1 / 8;
	}

	@media (--large) {
		font-size: var(--hdsplus-typography-body-300-font-size);
		line-height: var(--hdsplus-typography-body-300-line-height);
	}
}

.ticket {
	--border: 1px solid var(--token-color-border-primary);
	--borderRadius: var(--hdsplus-spacing-03);
	
	display: grid;
	grid-template-columns: 1fr;
	position: relative;
	border-radius: var(--borderRadius);
	overflow: hidden;
	z-index: 1;
	
	@nest :global(.hashidaysConference) & {
		--angle: 175deg;

		background: linear-gradient(var(--angle), rgba(255, 255, 255, 0.00) 48.12%, rgba(2, 168, 239, 0.23) 63.02%, #2E71E5 84.54%, #A067DA 100%);

		@media (--large) {
			--angle: 102deg;
		}
	}

	@media (--large) {
		grid-template-rows: 1fr;
		grid-template-columns: 3fr 2fr;
	}

	@nest :global(.hashiconfConference) &::before {
		pointer-events: none;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border: 1px solid transparent;
		border-radius: var(--borderRadius);
		background: linear-gradient(to bottom right, rgba(255,255,255,0.2), var(--conferenceGradientFull), rgba(255,255,255,0.2)) border-box;
		-webkit-mask: linear-gradient(rgba(255,255,255,1) 0 0) padding-box, linear-gradient(rgba(255,255,255,1) 0 0);
		-webkit-mask-composite: destination-out;
		mask-composite: exclude;
		opacity: 0.6;
	}
}

.border {
	position: absolute;
	inset: 0;
	pointer-events: none;
	width: 100%;
	height: 100%;
	border: var(--border);
	overflow: hidden;
	border-radius: var(--borderRadius);

	@nest :global(.hashiconfConference) & {
		display: none;
	}
}

.notches {
	pointer-events: none;
	position: relative;
	grid-column: 1 / -1;
	grid-row: 3 / 4;
	width: 100%;
	height: 2px;
	border-bottom: 2px dashed var(--token-color-border-strong);

	&::before, &::after {
		--dimensions: 35px;
		--halvedDimensions: calc(-1 * (var(--dimensions) / 2) - 1px);
	
		content: '';
		pointer-events: none;
		position: absolute;
		width: var(--dimensions);
		height: var(--dimensions);
		border-radius: 100%;
		background-color: var(--token-color-surface-primary);
		border: var(--border);

		@nest .faint & {
			background-color: var(--token-color-surface-faint);
		}
	}

	&::before {
		bottom: var(--halvedDimensions);
		left: var(--halvedDimensions);

		@media (--large) {
			bottom: unset;
			left: unset;
			top: var(--halvedDimensions);
			right: var(--halvedDimensions);
		}
	}

	&::after {
		bottom: var(--halvedDimensions);
		right: var(--halvedDimensions);
	}

	@media (--large) {
		grid-column: 1 / 2;
		grid-row: 1 / 3;
		height: 100%;
		border-bottom: none;
		border-right: 2px dashed var(--token-color-border-strong);
	}

	
	@nest :global(.hashiconfConference) & {
		border: none;
		
		&::before, &::after {
			display: none;
		}

		@media (--large) {
			width: 1px;
			background: linear-gradient(to bottom, var(--conferenceGradientFull));
			grid-column: 2 / -1;
			opacity: 0.6;
		}
	}
}

.detailsHeader {
	@media (--large) {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}

	@nest :global(.hashiconfConference) & {
		background: rgba(75, 75, 75, 0.2);
	}

	@nest :global(.hashidaysConference) & {
		background: white;
	}

	@nest :global(.hashidaysConference) & :global(.hdsplus-typography-label) {
		color: #2e71e5;
	}
}

.details {
	@nest :global(.hashiconfConference) & {
		background-color: var(--token-color-surface-primary);
	}

	@media (--large) {
		grid-column: 1 / 2;
		grid-row: 2 / 3;
	}
}

.optionsHeader {
	@nest :global(.hashidaysConference) & {
		background: white;
	}

	@media (--large) {
		grid-column: 2 / 3;
		grid-row: 1 / 2;
	}

	@nest :global(.hashiconfConference) & {
		background: rgba(75, 75, 75, 0.2);
	}
}

.options {
	@nest :global(.hashidaysConference) & {
		background-image:url("https://www.datocms-assets.com/2885/1738192354-group-2740.png");
		background-position: 100% 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	@media (--large) {
		grid-column: 2 / 3;
		grid-row: 2 / 3;
	}
	
	@nest :global(.hashiconfConference) & {
		background-color: var(--token-color-surface-primary);
	}
}
