.root {
	composes: g-grid-container from global;
}

.categories {
	display: grid;

	@media (--medium-up) {
		column-gap: var(--hdsplus-spacing-07);
		grid-template-columns: repeat(4, 1fr);
	}

	@media (--large) {
		grid-template-columns: repeat(var(--columns), 1fr);
		grid-template-rows: repeat(3, min-content);
		grid-auto-flow: column;
	}
}

.category {
	grid-column: 1 / -1;

	@media (--large) {
		grid-column: auto / span var(--span);
	}
}

.headingBox {
	background-image: var(--headingGradient);
	border-radius: var(--hdsplus-spacing-03);
	display: flex;
	justify-content: space-between;

	&.hasMarginTop {
		margin-top: var(--hdsplus-spacing-09);

		@media (--large) {
			margin-top: 0;
		}
	}
}

.headingText {
	margin: var(--hdsplus-spacing-08) 0 var(--hdsplus-spacing-04) var(--hdsplus-spacing-04);
	& p {
		color: var(--textColor);
	}
}

.categoryName {
	font-weight: var(--font-weight-semi-bold);
}

.icon {
	display: grid;
	place-items: center;
	background-color: black;
	border-radius: 50%;
	height: var(--hdsplus-spacing-08);
	width: var(--hdsplus-spacing-08);
	margin: var(--hdsplus-spacing-04);
}

.headingDescription {
	color: var(--token-color-foreground-faint);
	margin: var(--hdsplus-spacing-07) 0 var(--hdsplus-spacing-05) 0;
}

.link {
	margin-bottom: var(--hdsplus-spacing-08);
}

.gradient {
	height: 2px;
	background-image: var(--productGradient);
	margin-bottom: var(--hdsplus-spacing-07);
}

.products {
	padding: 0;
	display: grid;
	row-gap: var(--hdsplus-spacing-07);
	margin: 0;
	list-style-type: none;
	grid-template-columns: repeat(2, 1fr);
	column-gap: var(--hdsplus-spacing-04);

	@media (--medium-up) {
		grid-template-columns: repeat(4, 1fr);
		column-gap: var(--hdsplus-spacing-04);
	}

	@media (--large) {
		display: flex;
	}
}

.product {
	border-radius: var(--spacing-spacing-03, 8px);
	transition: background-color 0.25s;

	@media (--medium-up) {
		padding: var(--hdsplus-spacing-03);

		&:hover {
			background-color: var(--token-color-surface-strong);
		}
	}

	@media (--large) {
		flex: 1 1 0;
	}
}

.productInner {
	display: grid;
	row-gap: var(--hdsplus-spacing-03);
}

.productDescription {
	color: var(--token-color-foreground-faint);
	font-size: 13px;
}
